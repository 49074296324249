<template>
	<div class="Plan">
		<div class="search">
            <a-tree-select style="width: 200px;margin-right:10px" :maxTagCount="1" :treeData="treeDataSchool" v-model="DepartmentId" treeNodeFilterProp="title" show-search allow-clear :showCheckedStrategy="SHOW_PARENT" placeholder="请选择部门"/>

			<a-select show-search style="width: 100px;margin-bottom: 20px;margin-left:10px" v-model="Type" placeholder="类型" >
			    <a-select-option v-for="item in TypeList" :value="item">{{ item }}</a-select-option>
			</a-select>
			
            <a-month-picker v-model="Month" @change="changeMonth" style="margin-bottom: 20px;margin-left: 10px;" :format="monthFormat" placeholder="请选择月份"/>

            <a-button type="primary" style="margin-left: 10px;" @click="_search">查询</a-button>
			
            <a-button type="default" style="margin-left: 10px;" @click="_manage">管理</a-button>
        </div>
		
		<!-- 目标管理表格 -->
		<div class="table">
			<a-table bordered :data-source="dataSource" :columns="columns" :pagination="false">
				<template slot="name" slot-scope="text, record">
					<editable-cell :text="text" @change="onCellChange(record.key, 'name', $event)" />
				</template>

				<div slot="OrgName" slot-scope="text, record, index">
					<span>{{text}}</span>
					<a-icon type="plus" style="font-size: 10px;margin-left: 10px;cursor: pointer;" @click='_addPlan(record)' />
				</div>

				<div slot="operation"  slot-scope="text, record, index">
					<a-icon type="edit" style="cursor: pointer;margin-right: 1.25rem;" @click="_editPlan(record)"/>

					<a-popconfirm title="您确定要删除吗?" @confirm="_delPlan(record)" okText="确定" cancelText="取消">
						<a-icon type="delete" style="cursor: pointer;padding-left: 5px;"/>
					</a-popconfirm>
				</div>
			</a-table>
			<a-pagination v-if="tableTotal" v-model="dataPage" @change="ChangePage" :pageSize="dataPageSize" :total="tableTotal" style="margin-top: 20px;text-align: right;"/>
		</div>
		
		<a-drawer width="500px" title="编辑目标" placement="right" :closable="false" :visible="editVisible"  @close="editClose">
            <div class="data-action">
				<div class="data-group">
				    <div class="data-label">部门</div>
					<a-input class="data-item" disabled v-model="PlanDetail.OrgName"></a-input>
				</div>                
				<div class="data-group">
                    <div class="data-label">项目</div>
					<a-input class="data-item" disabled v-model="PlanDetail.ItemName"></a-input>
                </div>                
				<div class="data-group">
                    <div class="data-label">月份</div>
					<a-input class="data-item" disabled v-model="PlanDetail.Month"></a-input>
                </div>
                <div class="data-group">
                    <div class="data-label">目标1</div>
					<a-input-number class="data-item"  v-model="PlanDetail.T1"></a-input-number>
                </div>                  
				<div class="data-group">
                    <div class="data-label">目标2</div>
					<a-input-number class="data-item" v-model="PlanDetail.T2"></a-input-number>
                </div>  
				<div class="data-group">
                    <div class="data-label" >目标3</div>
					<a-input-number class="data-item" v-model="PlanDetail.T3"></a-input-number>
                </div>     
            </div>
			
			<div :style="{ position: 'absolute', bottom: 0, width: '100%', borderTop: '1px solid #e8e8e8', padding: '10px 16px', textAlign: 'left', left: 0, background: '#fff', borderRadius: '0 0 4px 4px',}">
				<a-button style="margin-bottom: 8px" @click="editClose">取消</a-button>
				<a-popconfirm title="您确定要修改吗?" @confirm="submitEdit()" okText="确定" cancelText="取消">
					<a-button style="margin-bottom: 8px;margin-left: 10px;" type="primary">确定</a-button>
				</a-popconfirm>
			</div>

			
		</a-drawer>
		
		
		<a-drawer width="500px" title="添加项目目标" placement="right" :closable="false" :visible="addVisible"  @close="editClose">
		    <div class="data-action">
				<div class="data-group">
				    <div class="data-label">部门</div>
					<a-input disabled class="data-item" v-model="addOriginData.OrgName"></a-input>
				</div>                
				<div class="data-group">
		            <div class="data-label">项目</div>
					<a-select class="data-item"  @change="selectType" v-model="addData.Type" placeholder="请选择目标项目" >
					    <a-select-option v-for="(item,index) in ItemTypeList" :value="item.ItemName">{{ item.ItemName }}</a-select-option>
					</a-select>
		        </div>                
				<div class="data-group">
		            <div class="data-label">月份</div>
					<a-input class="data-item" disabled v-model="addOriginData.Month"></a-input>
		        </div>
		        <div class="data-group">
		            <div class="data-label">目标1</div>
					<a-input-number class="data-item" v-model="addData.T1"></a-input-number>
		        </div>                  
				<div class="data-group">
		            <div class="data-label">目标2</div>
					<a-input-number class="data-item" v-model="addData.T2"></a-input-number>
		        </div>  
				<div class="data-group">
		            <div class="data-label" >目标3</div>
					<a-input-number class="data-item" v-model="addData.T3"></a-input-number>
		        </div>     
		    </div>
			
			<div :style="{ position: 'absolute', bottom: 0, width: '100%', borderTop: '1px solid #e8e8e8', padding: '10px 16px', textAlign: 'left', left: 0, background: '#fff', borderRadius: '0 0 4px 4px',}">
				<a-button style="margin-bottom: 8px" @click="editClose">取消</a-button>
				<a-popconfirm title="您确定要提交吗?" @confirm="submitAdd()" okText="确定" cancelText="取消">
					<a-button style="margin-bottom: 8px;margin-left: 10px;" type="primary">确定</a-button>
				</a-popconfirm>
			</div>
			
			
		</a-drawer>
		
		<!-- 项目管理 -->
		<a-drawer width="800px" title="项目管理" placement="right" :closable="false" :visible="manageVisible"  @close="editClose">

			<div>
				<a-button class="editable-add-btn" @click="handleAdd" style="margin-bottom: 0.9375rem;">添加项目</a-button>
				<a-table bordered :data-source="ItemData" :columns="itemColumns" :pagination="false">
					<div slot="operate"  slot-scope="text, record, index">
						<a-icon type="edit" style="cursor: pointer;margin-right: 1.25rem;" @click="_editItem(record)"/>
					</div>
				</a-table>
			</div>
			
		</a-drawer>
		
		<!-- 添加修改项目 -->
		<!-- 项目管理 -->
		<a-drawer width="500px" :title="PlanItem.Id ? '修改项目': '添加项目'" placement="right" :closable="false" :visible="itemEditVisible"  @close="itemEditClose">
			<div class="data-action">
				<div class="data-group">
				    <div class="data-label">名称</div>
					<a-input class="data-item"  v-model="PlanItem.ItemName"></a-input>
				</div>                
				<div class="data-group">
			        <div class="data-label">计算函数</div>
					<a-input class="data-item"  v-model="PlanItem.DataFunc"></a-input>
			    </div>                
				<div class="data-group">
			        <div class="data-label">必填</div>
					<a-select v-model="PlanItem.MustWrite" :default-value="PlanItem.MustWrite" class="data-item" mode="multiple"  style="width: 100%" placeholder="请选择必填" @change="selectMustWrite">
						<a-select-option v-for="(item,index) in TypeList" :value="item">{{ item }}</a-select-option>
					</a-select>
			    </div>
				<div class="data-group">
				    <div class="data-label">备注</div>
					<a-input class="data-item"  v-model="PlanItem.Remark"></a-input>
				</div>  
    
			</div>
			
			<div :style="{ position: 'absolute', bottom: 0, width: '100%', borderTop: '1px solid #e8e8e8', padding: '10px 16px', textAlign: 'left', left: 0, background: '#fff', borderRadius: '0 0 4px 4px',}">
				<a-button style="margin-bottom: 8px" @click="itemEditClose">取消</a-button>
				<a-button style="margin-bottom: 8px;margin-left: 10px;" type="primary" @click="itemSave">保存</a-button>
				
				
			</div>
			
		</a-drawer>
		
		<!-- 日志记录 -->
		<a-drawer width="800px" title="修改记录" placement="right" :closable="false" :visible="logVisible"  @close="logClose">
			<div>
				<a-table bordered :data-source="LogData" :columns="logColumns" :pagination="false">
				</a-table>
			</div>
			
		</a-drawer>
		
	</div>
</template>

<script>
	import moment from 'moment';
	export default {
		name: "plan",
		data() {
			return {
				userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
				TypeList: ['事业部','大区','城市','中心','顾问'],
				Type:'事业部',
				Month: this.getMonth(),
				treeDataSchool: [],
				DepartmentId: '4ae815d133588c71013358ad96450007',
				dataSource: [],
				SHOW_PARENT: 'SHOW_ALL',
				monthFormat: 'YYYY-MM',
				editVisible: false,
				addVisible: false,
				manageVisible: false,
				itemEditVisible: false,
				logVisible: false,
				PlanDetail: [],
				addOriginData: [],
				ItemTypeList: [],
				addData: [],
				ItemData: [],
				PlanItem: [],
				LogData: [],
				tableTotal: 0,
				dataPage: 1,
				dataPageSize: 10,
				
				columns: [
				    {
				        title: '部门',
				        dataIndex: 'OrgName',
						scopedSlots: {customRender: 'OrgName'},
				        width: 120,
				        align: 'center',
						key: 'OrgName',

				    },
				    {
				        title: '项目',
				        dataIndex: 'ItemName',
				        key: 'ItemName',
				        width: 120,
				        align: 'center',
				    },
					{
					    title: '月份',
					    dataIndex: 'Month',
					    key: 'Month',
					    width: 120,
					    align: 'center',
					},
				    {
				        title: '目标1',
				        dataIndex: 'T1',
				        key: 'T1',
				        width: 120,
				        align: 'center',
				    },
				    {
				        title: '目标2',
				        dataIndex: 'T2',
				        key: 'T2',
				        width: 120,
				        align: 'center',
				    },
				    {
				        title: '目标3',
				        dataIndex: 'T3',
				        key: 'T3',
				        width: 120,
				        align: 'center',
				    },
				    {
				        title: '操作',
				        dataIndex: 'operation',
				        key: 'operation',
				        scopedSlots: {customRender: 'operation'},
				        width: 120,
				        align: 'center',
				    },
				],
				
				itemColumns : [
					{
					    title: '名称',
					    dataIndex: 'ItemName',
					    width: 120,
					    align: 'center',
						key: 'ItemName',
					
					},
					{
					    title: '计算函数',
					    dataIndex: 'DataFunc',
					    key: 'DataFunc',
					    width: 120,
					    align: 'center',
					},
					{
					    title: '必填',
					    dataIndex: 'MustWrite',
					    key: 'MustWrite',
					    width: 120,
					    align: 'center',
					},
					{
					    title: '操作',
					    dataIndex: 'operate',
					    key: 'operate',
						scopedSlots: {customRender: 'operate'},
					    width: 120,
					    align: 'center',
					},
				],
				
				logColumns : [
					{
					    title: '项目名称',
					    dataIndex: 'ItemName',
					    width: 120,
					    align: 'center',
						key: 'ItemName',
					
					},
					{
					    title: '目标项',
					    dataIndex: 'TargetType',
					    key: 'TargetType',
					    width: 120,
					    align: 'center',
					},
					{
					    title: '旧数据',
					    dataIndex: 'OldValue',
					    key: 'OldValue',
					    width: 120,
					    align: 'center',
					},
					{
					    title: '新数据',
					    dataIndex: 'NewValue',
					    key: 'NewValue',
					    width: 120,
					    align: 'center',
					},
					{
					    title: '修改人',
					    dataIndex: 'CreateUserName',
					    key: 'CreateUserName',
					    width: 120,
					    align: 'center',
					},
					{
					    title: '修改时间',
					    dataIndex: 'CreateTime',
					    key: 'CreateTime',
					    width: 120,
					    align: 'center',
					},
				]
				
			}
		},    
		created() {
			this.$axios.get(8012, {}, res => {
				if (res.data.code == 1) {
					this.treeDataSchool = res.data.data;
				} else {
					this.$message.error(res.data.msg)
				}
			});
			this.data_field_list();

		},
		methods: {
			changeMonth(val, str) {
			    this.Month = str;
				this.data_field_list();
			},
			getMonth(num=1) {
				var myDate = new Date();
				let year = myDate.getFullYear();
				let mydate_month = myDate.getMonth(num) + 1 ;
				let val = year + '-' + this.getNum(mydate_month);
				return val;

			},        
			moment,
			getNum(val) {
				if (val < 10) {
					return '0' + val;
				} else {
					return val;
				}
			},
			_search() {
				// console.log(this.DepartmentId);
				if (this.Month == '' || this.Type == '' || this.DepartmentId == '') {
					this.$message.error('请将部门/类型/月份选择完整');
					return false;
				}
				this.data_field_list();
			},  
			ChangePage(val) {
				this.dataPage = val;
				this.data_field_list();
			},
			data_field_list() {
				let self = this;
				// this.$message.loading('加载中...', 0);
				this.$axios.get(8000160, {
					OrgId: self.DepartmentId,
					Month: self.Month,
					Type: self.Type,
					Page: self.dataPage,
					PageSize: self.dataPageSize,
				}, res => {
					// this.$message.destroy();
					if (res.data.code == 1) {
						self.dataSource = res.data.data;
						self.tableTotal = res.data.count;
						// console.log(self.columns.length);
						if (self.columns.length>0) {
							for (var i = 0; i < self.columns.length; i++) {
								if (self.columns[i].key == 'OrgName'){
									self.columns[i].customRender = (value, row, index) => {
									let listarr = [];
									let key = 0;//获取重复的值的key
									let arr_key = [];
									let str = '';
									
									if (value.length > 0) {
										str = value;
									}
					
									let obj = {
										children: str,
										attrs: {}
									};
									
									for (let s = 0; s < self.dataSource.length; s++) {
					
										let num = 0;
										let startIndex = '';
										if (listarr.indexOf(str) == -1) {
											for (let u = 0; u < self.dataSource.length; u++) {
												if (str == self.dataSource[u].OrgName) {
													if (!startIndex && startIndex !== 0) {
														startIndex = u;
													}
													if (num > 0) {
														arr_key.push(u)
													}
													num++;
												}
											}
											listarr[key] = str;
											if (num > 0) {
												if (index == startIndex) {
													obj.attrs.rowSpan = num;
													str =
				<div>
					<span>{str}</span>
					<a-icon type="plus" style="font-size: 10px;margin-left: 10px;cursor: pointer;" onclick={()=>{self._addPlan(row)}}/>
					<a-icon type="unordered-list" style="font-size: 10px;margin-left: 10px;cursor: pointer;" onclick={()=>{self._showLog(row)}} />
				</div>;
													obj.children =  str;
												}
											}
										}
									}
									
									if (arr_key.indexOf(index) !== -1) {
										obj.attrs.colSpan = 0;
										obj.children = str;
									}
									
					
									return obj;
										
										
									}
								}
							}
						}
						// console.log(this.columns);


					} else {
						self.tableTotal = 0;
						self.dataSource = [];
						this.$message.error(res.data.msg);
					}
				})
			},
			_manage(){
				this.manageVisible = true;
				this.getItemData();
			},
			getItemData() {
				this.$axios.get(8000164,{},res => {
					if (res.data.code == 1) {
						this.ItemData = res.data.data;
					}
				})
			},
			_editPlan(record) {
				// console.log(record);
				this.$axios.get(8000163,{
					PlanTempId : record.PlanTempId || ''
				},res => {
					if (res.data.code == 1) {
						this.editVisible = true;
						this.PlanDetail = res.data.data;
						if (this.PlanDetail.UserId) {
							this.PlanDetail.OrgId = this.PlanDetail.UserId;
							this.PlanDetail.OrgName = this.PlanDetail.UserName;
						}
					} else { //添加
						this.addVisible = true;
						this.addOriginData.OrgName = record.OrgName;
						this.addOriginData.OrgId = record.OrgId;
						// this.addData.Type = record.ItemName;
						this.addOriginData.Month = record.Month;
						this.$axios.get(8000164,{
							
						},res => {
							if (res.data.code == 1) {
								this.ItemTypeList = res.data.data;
							}
						})
					}
				});
			},
			submitEdit() {
				let self = this;
				this.$axios.post(8000162,{
					PlanTempId: self.PlanDetail.Id,
					T1: self.PlanDetail.T1,
					T2: self.PlanDetail.T2,
					T3: self.PlanDetail.T3
				},res => {
					if (res.data.code == 1) {
						this.$message.success(res.data.msg);
						this.editVisible = false;
						this.data_field_list();
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},
			editClose() {
				this.editVisible = false;
				this.addVisible = false;
				this.manageVisible = false;
				this.addData = [];
			},
			_delPlan(record) {
				this.$axios.post(8000161,{
					PlanTempId: record.PlanTempId
				},res => {
					if (res.data.code == 1) {
						this.$message.success(res.data.msg);
						this.data_field_list();
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},
			_addPlan(record){
				console.log(record);
				this.$axios.get(8000164,{
					
				},res => {
					if (res.data.code == 1) {
						this.ItemTypeList = res.data.data;
					}
				})
				this.addVisible = true;
				this.addOriginData = record;
				// this.addData.Type = record.ItemName;
			},
			selectType(val) {
				this.addData.Type = val;
			},
			submitAdd() {
				let self = this;
				this.$axios.post(8000162,{
					Month: self.addOriginData.Month,
					ItemName: self.addData.Type,
					UserId: this.Type == '顾问' ? self.addOriginData.OrgId : '',
					UserName: this.Type == '顾问' ? self.addOriginData.OrgName : '',
					OrgId: this.Type != '顾问' ? self.addOriginData.OrgId : '',
					OrgName: this.Type != '顾问' ? self.addOriginData.OrgName : '',
					T1: self.addData.T1,
					T2: self.addData.T2,
					T3: self.addData.T3
				},res => {
					if (res.data.code == 1) {
						this.$message.success(res.data.msg);
						this.addVisible = false;
						this.data_field_list();
					} else {
						this.$message.error(res.data.msg);
					}
				});
				this.addOriginData = [];
				this.addData = [];
			},
			
			//项目管理
			_editItem(record) {
				this.itemEditVisible = true;
				this.PlanItem = record;
				if (record.MustWrite) {
					this.PlanItem.MustWrite = record.MustWrite.split('#');
				}
			},
			handleAdd() {
				this.itemEditVisible = true;
				this.PlanItem = [];
			},
			selectMustWrite(val) {
				this.PlanItem.MustWrite = val;
			},
			itemSave() {
				let self = this;
				if (!self.PlanItem.ItemName) {
					this.$message.warning('名称必填');
					return false;
				}
				console.log(self.PlanItem);
				this.$axios.post(8000200,{
					Id: self.PlanItem.Id || '',
					ItemName: self.PlanItem.ItemName,
					MustWrite: self.PlanItem.MustWrite || '',
					DataFunc: self.PlanItem.DataFunc || '',
					Remark: self.PlanItem.Remark || '',
				}, res => {
					if (res.data.code == 1) {
						this.$message.success(res.data.msg);
						this.itemEditClose();
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},
			itemEditClose() {
				this.itemEditVisible = false;
				this.getItemData();
			},
			
			// 日志
			_showLog(row) {
				console.log(row);
				let self = this;
				this.logVisible = true;
				this.$axios.get(8000201,{
					Month: row.Month,
					UserId: row.Type == '顾问' ? row.OrgId : '',
					OrgId: row.Type != '顾问' ? row.OrgId : '',
				}, res => {
					if (res.data.code == 1) {
						this.LogData = res.data.data;  
					} else {
						this.LogData = [];
					}
				})
			},
			logClose() {
				this.logVisible = false;
			},
		}
	}
	
</script>

<style scoped lang="less">
.Plan {
    background: #ffffff;
    margin-top: 15px;
    min-height: 800px;
    padding: 15px;
}
.editDrawer {
	width:600px;
}

.data-action {
    margin-bottom: 60px;

    .data-group {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .data-label {
            font-size: 14px;
            width: 60px;
        }

        .data-item {
            flex: 1;
        }
    }
}
</style>